import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const PromoSection = () => {
  const [code, setCode] = useState<string | null>('SST12ABOZ4');
  const { t, i18n } = useTranslation('promocode');

  const getPromoCode = async () => {
    if (!localStorage.getItem('snelStartPromoCode')) {
      try {
        const response = await fetch(
          'https://api.flowyour.money/u/generate-promo-code?promoCodeType=snelstart'
        );
        if (response.ok) {
          const promoCode = await response.text();
          setCode(promoCode);
          // Add Promo code to LS
          localStorage.setItem('snelStartPromoCode', promoCode);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setCode(localStorage.getItem('snelStartPromoCode'));
    }
  };

  useEffect(() => {
    getPromoCode();
  }, []);

  return (
    <section className="promo">
      <div className="container">
        <h2 className="promo__title promo__title--primary">
          <Trans
            i18nKey="promocode:snelstart.title"
            components={[
              <span/>
            ]}
          />
        </h2>
        <div className="promo__text">
          {t('snelstart.description')}
        </div>
        <h3 className="promo__subtitle">{t('snelstart.coupon_code')}</h3>
        <div className="promo__code">{code}</div>
      </div>
    </section>
  );
};

export default PromoSection;
