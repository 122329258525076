import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '../components/Layout';
import Seo from '../components/SEO/seo';
import PromoSection from '../components/SnelStartPromoCode/PromoSection';
import StepsSection from '../components/bunq-deal/StepsSection';
import ScreensSection from '../components/bunq-deal/ScreensSection';
import CoworkingSection from '../components/bunq-deal/CoworkingSection';
import RulesSection from '../components/bunq-deal/RulesSection';
import AppSection from '../components/bunq-deal/AppSection';

const SnelStartPromoCode = (page: PageProps) => {
  return (
    <Layout>
      <Seo page={page} title="SnelStart + Flow" />
      <main className="page-bunq-deal page-snelstart-promocode">
        <PromoSection />
        <StepsSection partner={'snelstart'} />
        <ScreensSection partner={'snelstart'} />
        <RulesSection partner={'snelstart'} />
      </main>
    </Layout>
  );
};

export default SnelStartPromoCode;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
