import React from 'react';
import screens from '../../assets/images/bunq/devices.png';
import sst_screens from '../../assets/images/snelstart/promo.png';

const ScreensSection = (props) => {
  return (
    <section className="screenshots">
      <div className="screenshots__wrapper">
        <img src={props.partner === 'snelstart' ? sst_screens : screens} alt="" className="screenshots__img" />
      </div>
    </section>
  );
};

export default ScreensSection;
