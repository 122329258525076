import React from 'react';
import illustration from '../../assets/images/bunq/flow-illustration.png';
import sst_illustration from '../../assets/images/snelstart/promo2.png';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const RulesSection = (props) => {
  const { t, i18n } = useTranslation('promocode');
  return (
    <section className="rules">
      <div className="container">
        <div className="rules__wrapper">
          <div className="rules__info">
            <h2 className="rules__title">{t('yourmoney.yourmoney', 'Your money')}</h2>
            <h2 className="rules__title rules__title--violet">{t('yourmoney.yourrules', 'Your rules')}</h2>
          </div>
          <img src={props.partner === 'snelstart' ? sst_illustration : illustration} alt="rules" className="rules__img" />
        </div>
        <div className="rules__article">
          <h3 className="rules__subtitle">{t('yourmoney.domore', 'Do more with your money')}</h3>
          <div className="rules__text">
            {t('yourmoney.description')}
          </div>
        </div>
      </div>
    </section>
  );
};

export default RulesSection;
